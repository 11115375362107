$custom-primary-color: #ac2724;
$custom-secondary-color: #0f3786;
$custom-tertiary-color: #9a9c9d;

$event-primary-color: #5095b3;
$event-secondary-color: #002e58;

// Here you can add other styles
.nav-item{
  //background-color: white!important;
}

.nav-link-danger.nav-link.active{
  background-color: #ffffff!important;
  color:black!important;
}


.nav-title{
  color: black!important;
}

.nav-link.active{
  background-color: $custom-primary-color !important;
  color:white!important;
}

.nav-link{
  color:black!important;
}


.sidebar .nav-link.active .nav-icon {
  color: grey;
}


.nav-link.nav-link-danger:hover, .nav-link:hover  {
  background-color: $custom-primary-color!important;
  color: white!important;
}

.app-header.navbar{
  background-color: $custom-secondary-color!important;
}

.customNavbar {
  background-color: $custom-secondary-color!important;
}

.sidebar-minimizer{
  background-color: #ffffff!important;
}


.navbar-toggler-icon {
  height: 23px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23B6CF53' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")!important;
}

 .switch-primary .switch-input:checked + .switch-slider {
   background-color: $custom-primary-color!important;
   border-color: #c8ced3!important;
 }

.switch-primary .switch-input:checked + .switch-slider::before {
  border-color: #c8ced3;
}

.bold{
  font-style: bold;
}
.font-weight-bold{
  font-weight: 700!important;
}

.italic{
  font-style: italic;
}

.alignLeft{
  text-align: left;
}
.alignRight{
  text-align: right;
}

.alignCenter{
  text-align: center;
}

.verticalAlignMiddle{
  margin-top : auto;
  margin-bottom : auto;
}
.marginBottom10{
  margin-bottom : 10px;
}

.marginLRauto{
  margin-left : auto;
  margin-right : auto;
}

.marginX10{
  margin-left : 10px;
  margin-right : 10px;
}

.media-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-top: 1.25rem;
}

.media-body {
  -ms-flex: 1;
  flex: 1;
}

.mt-3, .my-3 {
  margin-top: 1.25rem!important;
}

.mr-3, .mx-3 {
  margin-right: 1.25rem!important;
}

.position-relative {
  position: relative!important;
}

.no-padding{
  padding:0px
}


.buttonColored{
  background-color: $custom-primary-color;
  borderColor: $custom-primary-color!important;
  color: white;
}



.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 48px;
  //width: 98%;
  //padding: 0 8px;
  //border: 1px solid rgb(214, 216, 218);
  //border-radius: 6px;
  &:focus-within {
   // border: 1px solid #0052cc;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 8px 0 0 0;
    li {
      width: auto;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      padding: 0 8px;
      font-size: 14px;
      list-style: none;
      border-radius: 6px;
      margin: 0 8px 8px 0;
      background: #0052cc;
      span {
        margin-top: 3px;
      }
      i {
        font-size: 14px;
        margin-left: 15px;
        color: #fff;
        border-radius: 10%;
        //background: #fff;
        cursor: pointer;
      }
    }
  }
  input {
    flex:1;
    //border: none;
    border-style: solid;
    border-width: 1px;
    border-color: #e4e7ea;
    height: 46px;
    width: 95%;
    font-size: 14px;
    //padding: 4px 0 0 0;
    padding-bottom: 15px;
    padding-top: 15px;
    &:focus {
      outline: transparent;
    }
  }
}

@media screen and (max-width: 567px) {
  .tags-input {
    width: calc(100vw - 32px);
  }
}


.tabCustom{
  /*
  border: 1px solid;
  border-top-color: transparent;
  border-bottom-color: #c8ced3;
  border-left-color: transparent;
  border-right-color: transparent;
   */
}



.tabCustom.active{
  //border: 1px solid #c8ced3;
  //border-bottom-color: transparent;
  background-color:$custom-primary-color;
  color: white!important;
}

.tabCustom.active > .nav-link{
  color: white!important;
}

.shadow-custom {
  box-shadow: 0 0.12rem 0.5rem rgba(0, 0, 0, 0.15);
}

.mean-card {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;

}
.results-mean-card-header {
  font-size: 40pt;
  background: #FFF500;
}

.results-question-card {
  border: none;

  .results-question-card-header {
    border-radius: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 25pt;
    font-weight: 500;
    background: white;
    color: black;
  }

  .results-question-card-body {
    font-size: 2rem;
  }
}

.rand-results-card {
  border: none;
  .rand-results-card-header {
    border-radius: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 25pt;
    font-weight: 500;
    background: white;
    color: black;
  }
  .rand-results-card-body {
    font-size: 2rem;
  }
}

.randButton {
  color: white;
  letter-spacing: 2.5px;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
  border: 5px solid white;
  border-radius: 18px;
  box-shadow: 5px 8px 15px rgba(0, 0, 0, 0.5);
  margin: 4px 2px;
}

.appropriato {
  background-image: linear-gradient(rgb(84, 184, 91), rgb(48, 112, 53));
}

.incerto {
  background-image: linear-gradient(rgb(245, 195, 66), rgb(122, 118, 34));
}

.inappropiato {
  background-image: linear-gradient(rgb(234, 51, 35), rgb(117, 31, 20));
}

.ranking-row {
  &:not(:first-child) {
    margin-top: 10px;
  }

  font-weight: 500;
  color: $event-secondary-color;

  .diagnosis-card {
    border-radius: 5px;
    z-index:10;
    padding: 3px 7px;
  }

  .results-progress-bar {
    font-size: 14pt;
    border-radius: 0;
  }

  .results-progress-bar.empty-progress .progress-bar{
    color: inherit;
  }

  .results-progress-bar.progress-bar-ok .progress-bar{
    background-color: $event-secondary-color;
  }

  .results-progress-bar.progress-bar-wrong .progress-bar{
    background-color: $event-primary-color;
  }
}

.user-result-card {
  border-radius: 5px;
  z-index:10;
  padding: 3px 7px;
  border: 2px solid $event-primary-color;
}

.backgroundPrimary {
  backgroundColor: $custom-tertiary-color;
}

// modale di errore ModalError
.modal-header-error {
  background-color: $custom-primary-color;
  color:white;
  justify-content: center;
}

.modal-header-error > .close{
  opacity:unset;
  color:white;
}

.modal-header-error-icon{
  text-align:center
}

.modal-body-error{
  text-align: center;
}

.modal-header-error > .close{
  opacity:unset;
  color:white;
}


.modal-body-error{
  text-align: center;
}

.img-error{
  width:40%
}

.alignSelfCenter{
  align-self: center;
}

.modalWidth900px {
  max-width: 900px;
}

.modalWidth1024px {
  max-width: 90%;
}

.modalWidth1024px > .modal-content{
  height: 80%;
}

.fr-box.fr-basic{
  height: 500px;
}

.fr-box.fr-basic.fr-top .fr-wrapper {
  height: 80%;
}

// CSS UTILE PER LA CHAT DELL'HELPDESK

.pic {
  width: 4rem;
  height: 4rem;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
}

.contact {
  position: relative;
  margin-bottom: 1rem;
  padding-left: 5rem;
  height: 4.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contact .pic {
  position: absolute;
  left: 0;
}
.contact .name {
  font-weight: 500;
  margin-bottom: 0.125rem;
}
.contact .message, .contact .seen {
  font-size: 0.9rem;
  color: #999;
}
.contact .badge {
  box-sizing: border-box;
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
  font-size: 0.9rem;
  padding-top: 0.125rem;
  border-radius: 1rem;
  top: 0;
  left: 2.5rem;
  background: #333;
  color: white;
}

.contacts {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-6rem, -50%);
  width: 24rem;
  height: 32rem;
  padding: 1rem 2rem 1rem 1rem;
  box-sizing: border-box;
  border-radius: 1rem 0 0 1rem;
  cursor: pointer;
  background: white;
  box-shadow: 0 0 8rem 0 rgba(0, 0, 0, 0.1), 2rem 2rem 4rem -3rem rgba(0, 0, 0, 0.5);
  transition: transform 500ms;
}
.contacts h2 {
  margin: 0.5rem 0 1.5rem 5rem;
}
.contacts .fa-bars {
  position: absolute;
  left: 2.25rem;
  color: #999;
  transition: color 200ms;
}
.contacts .fa-bars:hover {
  color: #666;
}
.contacts .contact:last-child {
  margin: 0;
}
.contacts:hover {
  transform: translate(-23rem, -50%);
}

.chat {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: auto;
  height: auto;
  z-index: 2;
  box-sizing: border-box;
  border-radius: 1rem;
  background: white;
  box-shadow: 0 0 8rem 0 rgba(0, 0, 0, 0.1), 0rem 2rem 4rem -3rem rgba(0, 0, 0, 0.5);
}
.chat .contact.bar {
  flex-basis: 3.5rem;
  flex-shrink: 0;
  margin: 1rem;
  box-sizing: border-box;
}
.chat .messages {
  padding: 1rem;
  background: #F7F7F7;
  flex-shrink: 2;
  overflow-y: auto;
  box-shadow: inset 0 2rem 2rem -2rem rgba(0, 0, 0, 0.05), inset 0 -2rem 2rem -2rem rgba(0, 0, 0, 0.05);
}
.chat .messages .time {
  font-size: 0.8rem;
  background: #EEE;
  padding: 0.25rem 1rem;
  border-radius: 2rem;
  color: #999;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}
.chat .messages .message {
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  margin: 1rem;
  background: white;
  color:black;
  border-radius: 1.125rem 1.125rem 1.125rem 0;
  min-height: 2.25rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 66%;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.075), 0rem 1rem 1rem -1rem rgba(0, 0, 0, 0.1);
}
.chat .messages .message.parker {
  margin: 1rem 1rem 1rem auto;
  border-radius: 1.125rem 1.125rem 0 1.125rem;
  background: $custom-secondary-color;
  color: white;
}
.chat .messages .message .typing {
  display: inline-block;
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0rem;
  box-sizing: border-box;
  background: #ccc;
  border-radius: 50%;
}
.chat .messages .message .typing.typing-1 {
  -webkit-animation: typing 3s infinite;
  animation: typing 3s infinite;
}
.chat .messages .message .typing.typing-2 {
  -webkit-animation: typing 3s 250ms infinite;
  animation: typing 3s 250ms infinite;
}
.chat .messages .message .typing.typing-3 {
  -webkit-animation: typing 3s 500ms infinite;
  animation: typing 3s 500ms infinite;
}
.chat .input {
  box-sizing: border-box;
  flex-basis: 4rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding: 0 0.5rem 0 1.5rem;
}
.chat .input i {
  font-size: 1.5rem;
  margin-right: 1rem;
  color: #666;
  cursor: pointer;
  transition: color 200ms;
}
.chat .input i:hover {
  color: #333;
}
.chat .input input {
  border: none;
  background-image: none;
  background-color: white;
  padding: 0.5rem 1rem;
  margin-right: 1rem;
  border-radius: 1.125rem;
  flex-grow: 2;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1), 0rem 1rem 1rem -1rem rgba(0, 0, 0, 0.2);
  font-family: Red hat Display, sans-serif;
  font-weight: 400;
  letter-spacing: 0.025em;
}
.chat .input input:placeholder {
  color: #999;
}

@-webkit-keyframes typing {
  0%, 75%, 100% {
    transform: translate(0, 0.25rem) scale(0.9);
    opacity: 0.5;
  }
  25% {
    transform: translate(0, -0.25rem) scale(1);
    opacity: 1;
  }
}

@keyframes typing {
  0%, 75%, 100% {
    transform: translate(0, 0.25rem) scale(0.9);
    opacity: 0.5;
  }
  25% {
    transform: translate(0, -0.25rem) scale(1);
    opacity: 1;
  }
}
// FINE CSS UTILE PER LA CHAT DELL'HELPDESK


//MARQUEE
.wrapper {
  max-width: 100%;
  overflow: hidden;
}

.marquee2 {
  white-space: nowrap;
  display: inline-block;
  padding-left: 100%;
}

@-webkit-keyframes marquee2 {
  0% {
    transform: translate3d(0%, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}


@keyframes marquee2 {
  0% {
    transform: translate3d(0%, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
// FINE MARQUEE


.switchIndex0  .switch-slider {
  z-index: 0;
}

#containerSwitchCustomColor .switch-input:not(:checked) + .switch-slider {
  background-color: red;
  border-color: darkred;
  color:white
}

#containerSwitchCustomColor .switch-input:checked + .switch-slider {
  background-color: green;
  border-color: darkgreen;
  color:black
}
